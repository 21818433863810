@import url(font.css);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: white; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

li {
  color: white;
  font-family: "Bahnschrift";
  font-size: 20px;

  margin: 15px 0;
}

.box {
  width: 100px;
  height: 100px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: green;
  font-weight: 600;
  color: white;
}

.redShadow {
  text-shadow: -3px 0 red;
}
.greenShadow {
  text-shadow: -3px 0 red;
}

.glitch {
  position: absolute;
  /* top: 35%; */
  left: 0;
  right: 0;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.top {
  -webkit-clip-path: inset(0 0 41% 0);
  clip-path: inset(0 0 41%);
}

.bottom {
  font-size: 100px;
  -webkit-clip-path: inset(58% 0 0 0);
  clip-path: inset(58% 0 0 0);
}
