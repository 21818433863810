/* File: font.css */

@font-face {
    font-family: 'AlphaCentauri';
    src: local('AlphaCentauri'),
         url('./fonts/Alphacentauri-MVY4p.ttf') format('truetype');
}

@font-face {
    font-family: 'Bahnschrift';
    src: local('Bahnschrift'),
         url('./fonts/Bahnschrift_Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'LemonMilk';
    src: local('LemonMilk'),
         url('./fonts/LEMONMILK-Regular.otf') format('opentype');
}
