.grid-container {
  /* display: flex; */
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  /* border: 3px solid black; */
}

.grid-column {
  flex: 1;
  overflow: hidden;
}

.grid-item-container {
  /* display: flex; */
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.grid-item {
  /* flex: 1; */
  /* margin: 10px; */
  position: absolute;
  /* transform: translate(50%, -50%); */
  /* display: inline-block; */
  /* padding: 10px; */
  /* min-width: 200px; */
}

.grid-item img {
  width: 100%;
  object-fit: cover;
}
